var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "upsell-selection-card" },
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { attrs: { color: "black" } }, [
            _vm._v("mdi-numeric-1-circle-outline"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-2" }, [_vm._v("Select Products")]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [{ name: "bar", rawName: "v-bar" }],
          staticClass: "scroll-container overflow-y-auto",
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("v-data-table", {
                ref: "sortableList",
                staticClass: "el2",
                attrs: {
                  items: _vm.items,
                  headers: _vm.headers,
                  "hide-default-footer": "",
                  "hide-default-header": "",
                  dense: "",
                  "disable-pagination": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ isSelected, item }) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "upsell-selection-row",
                            attrs: { active: isSelected },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleEither(item)
                              },
                            },
                          },
                          [
                            item.group
                              ? _c(
                                  "td",
                                  [
                                    _c("v-row", { staticClass: "pt-1" }, [
                                      _c("div", { staticClass: "group-icon" }),
                                      _c(
                                        "div",
                                        { staticClass: "group-title" },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("td", [
                                  _c("div", { staticClass: "item-title" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ]),
                            _c(
                              "td",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    color: "rgba(98, 197, 241, 1)",
                                    "input-value": isSelected,
                                    "hide-details": "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      item.group
                                        ? _vm.toggleCategory(item)
                                        : _vm.toggleItem(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedItems,
                  callback: function ($$v) {
                    _vm.selectedItems = $$v
                  },
                  expression: "selectedItems",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }