const DayOfWeekOrderNumber = {};

DayOfWeekOrderNumber[DayOfWeekOrderNumber[0] = 'MONDAY'] = 0;
DayOfWeekOrderNumber[DayOfWeekOrderNumber[1] = 'TUESDAY'] = 1;
DayOfWeekOrderNumber[DayOfWeekOrderNumber[2] = 'WEDNESDAY'] = 2;
DayOfWeekOrderNumber[DayOfWeekOrderNumber[3] = 'THURSDAY'] = 3;
DayOfWeekOrderNumber[DayOfWeekOrderNumber[4] = 'FRIDAY'] = 4;
DayOfWeekOrderNumber[DayOfWeekOrderNumber[5] = 'SATURDAY'] = 5;
DayOfWeekOrderNumber[DayOfWeekOrderNumber[6] = 'SUNDAY'] = 6;

Object.freeze(DayOfWeekOrderNumber);

export default DayOfWeekOrderNumber;
