<template>
    <v-row class="bulk-edit-radio-row" no-gutters>
        <v-col align-self="center" md="4">
            <div class="bulk-edit-radio-row__toggle pl-5 pr-5">
                <v-btn
                    v-model="enabled"
                    @click="onToggleChange(!enabled)"
                    small
                    icon
                    active-class="active"
                    :color="enabled ? 'rgb(98, 197, 241)' : null"
                >
                    <v-icon>mdi-power-standby</v-icon>
                </v-btn>
                <span class="font-weight-bold ml-3">{{label}}</span>
            </div>
        </v-col>
        <v-col>
            <v-switch
                :disabled="!enabled"
                v-model="value"
                @change="onRadioChange"
                color="#93BD20"
                small
            ></v-switch>
        </v-col>
    </v-row>

</template>

<script>
export default {
    name: "BulkEditRow",
    data() {
        return {
            enabled: false,
            value: undefined,
        };
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        changeKey: {
            type: String,
            required: true,
        },
        radioLabels: {
            type: Array,
            require: true,
        },
    },
    methods: {
        onToggleChange(val) {
            this.enabled = val;
            if (!val) {
                this.value = undefined;
            } else {
                this.value = this.value ? this.value : false;
            }
            this.$emit("input", {key: this.changeKey, value: this.value});

        },
        onRadioChange(val) {
            this.$emit("input", {key: this.changeKey, value: val})
        }
    },
};
</script>
<style lang="scss" scoped>
    .bulk-edit-radio-row {
        height: 68px;
        border: 1px solid #e4e4e4;
        margin-top: -1px;
        &__toggle {
            height: 28px;
        }
    }
</style>
