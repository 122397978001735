<template>
  <v-card>
    <v-card-title><v-icon color="black">mdi-numeric-2-circle-outline</v-icon> <span class="ml-2">Select fields</span></v-card-title>
    <v-card-text class="ml-0 mr-0 pl-0 pr-0">
        <v-row no-gutters style="background: #F4F4F4">
            <v-col><span class="pl-5">Enable</span></v-col>
            <v-col>Reset All</v-col>
        </v-row>
      <BulkEditRadioRow
        label="Item Available"
        changeKey="inventoryItemAvailable"
        :radioLabels="yesNoOpts"
        @input="onRadioInput"
      />
      <BulkEditTimeRow @input="onTimeInput" />
      <BulkEditRadioRow
        label="Employee Item"
        changeKey="employeeItem"
        :radioLabels="yesNoOpts"
        @input="onRadioInput"
      />
      <BulkEditRadioRow
        label="Featured Item"
        changeKey="inventoryFeatureItem"
        :radioLabels="yesNoOpts"
        @input="onRadioInput"
      />
      <BulkEditRadioRow
        label="Alcohol"
        changeKey="alcoholItem"
        :radioLabels="yesNoOpts"
        @input="onRadioInput"
      />
      <BulkEditSelectRow
        label="Category"
        changeKey="inventoryTitleDescription"
        :items="categoryItems"
        @input="onSelectInput"
      />
      <BulkEditSelectRow
        label="Subcategory"
        changeKey="inventorySubTitleDescription"
        :items="subCategoryItems"
        @input="onSelectInput"
      />
      <BulkEditPlatforms
        label="Platforms"
        changeKey="disabledPlatforms"
        @input="onPlatformsInput"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import BulkEditRow from "components/bulk_edit_row";
import BulkEditTimeRow from "components/bulk_edit_time_row";
import BulkEditRadioRow from "components/bulk_edit_radio_row";
import BulkEditSelectRow from "components/bulk_edit_select_row";
import BulkEditPlatforms from "components/bulk_edit_platforms";

import { mapGetters } from "vuex";
import Vue from 'vue';

export default {
    name: "BulkEditForm",
    components: {
        BulkEditRow,
        BulkEditTimeRow,
        BulkEditRadioRow,
        BulkEditSelectRow,
        BulkEditPlatforms
    },
    data() {
        return { changedProperties: {}, yesNoOpts: ["Yes", "No"] };
    },
    computed: {
        categoryItems() {
            return this.productCategories
                .filter((el) => el.inventoryTitleDescription !== "SHOW ALL")
                .map((el) => el.inventoryTitleDescription);
        },
        subCategoryItems() {
            const category = this.productCategories
                .find((el) => el.inventoryTitleDescription === this.changedProperties["inventoryTitleDescription"]);

            if (!category || !category.inventorySubTitles) {
                return [];
            }

            return category.inventorySubTitles.map(x =>
                x.inventorySubTitleDescription
            );
        },
        ...mapGetters(["productCategories"]),
    },
    methods: {
        onTimeInput(val) {
            if (val === undefined) {
                delete this.changedProperties.availability;
            } else {
                this.changedProperties.availability = val.availability;
            }
            this.emitInput();
        },
        onRadioInput(payload) {
            if (payload.value == undefined) {
                delete this.changedProperties[payload.key];
            } else {
                this.changedProperties[payload.key] = payload.value;
            }
            this.emitInput();
        },
        onSelectInput(payload) {
            if (payload.value == undefined) {
                delete this.changedProperties[payload.key];
                if (payload.key === 'inventoryTitleDescription') {
                    Vue.delete(this.changedProperties, 'inventorySubTitleDescription');
                    delete this.changedProperties['inventorySubTitleDescription'];
                }
            } else {
                Vue.set(this.changedProperties, payload.key, payload.value);
                if (payload.key === 'inventoryTitleDescription'
                    && 'inventorySubTitleDescription' in this.changedProperties) {
                    Vue.set(this.changedProperties, 'inventorySubTitleDescription', null);
                }
            }

            this.emitInput();
        },
        onPlatformsInput(payload) {
            if (payload.value == undefined) {
                delete this.changedProperties.disabledPlatforms
            } else {
                var disabled = []
                for (const key in payload.value) {
                    if (payload.value.hasOwnProperty(key)) {
                        const element = payload.value[key];
                        if (element.enabled == 0) {
                            disabled.push(element.id)
                        }
                    }
                }
                this.changedProperties.disabledPlatforms = disabled.join(",")
                this.emitInput()
            }
        },
        emitInput() {
            this.$emit("input", this.changedProperties);
        },
    },
};
</script>
