var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-icon", { attrs: { color: "black" } }, [
            _vm._v("mdi-numeric-2-circle-outline"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-2" }, [_vm._v("Select fields")]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "ml-0 mr-0 pl-0 pr-0" },
        [
          _c(
            "v-row",
            {
              staticStyle: { background: "#F4F4F4" },
              attrs: { "no-gutters": "" },
            },
            [
              _c("v-col", [
                _c("span", { staticClass: "pl-5" }, [_vm._v("Enable")]),
              ]),
              _c("v-col", [_vm._v("Reset All")]),
            ],
            1
          ),
          _c("BulkEditRadioRow", {
            attrs: {
              label: "Item Available",
              changeKey: "inventoryItemAvailable",
              radioLabels: _vm.yesNoOpts,
            },
            on: { input: _vm.onRadioInput },
          }),
          _c("BulkEditTimeRow", { on: { input: _vm.onTimeInput } }),
          _c("BulkEditRadioRow", {
            attrs: {
              label: "Employee Item",
              changeKey: "employeeItem",
              radioLabels: _vm.yesNoOpts,
            },
            on: { input: _vm.onRadioInput },
          }),
          _c("BulkEditRadioRow", {
            attrs: {
              label: "Featured Item",
              changeKey: "inventoryFeatureItem",
              radioLabels: _vm.yesNoOpts,
            },
            on: { input: _vm.onRadioInput },
          }),
          _c("BulkEditRadioRow", {
            attrs: {
              label: "Alcohol",
              changeKey: "alcoholItem",
              radioLabels: _vm.yesNoOpts,
            },
            on: { input: _vm.onRadioInput },
          }),
          _c("BulkEditSelectRow", {
            attrs: {
              label: "Category",
              changeKey: "inventoryTitleDescription",
              items: _vm.categoryItems,
            },
            on: { input: _vm.onSelectInput },
          }),
          _c("BulkEditSelectRow", {
            attrs: {
              label: "Subcategory",
              changeKey: "inventorySubTitleDescription",
              items: _vm.subCategoryItems,
            },
            on: { input: _vm.onSelectInput },
          }),
          _c("BulkEditPlatforms", {
            attrs: { label: "Platforms", changeKey: "disabledPlatforms" },
            on: { input: _vm.onPlatformsInput },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }