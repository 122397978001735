import DayOfWeekOrderNumber from "components/product_edit/DayOfWeekOrderNumber";
import moment from "moment";
import 'lodash.combinations';
import _ from "lodash";

export const convertMultipleDaysEntryToSeparateEntries = (entries) => {
    const result = [];
    for (const entry of entries) {
        for (const day of entry.days) {
            result.push({
                ...entry,
                days: undefined,
                day,
            });
        }
    }
    return result;
}

export const convertSeparateEntriesToMultipleDaysEntries = separateEntries => {
    const groupedByDay = [];
    const getAvailabilityKey = x => `${x.startTimeLocal}-${x.endTimeLocal}.nextDay:${x.nextDay}`;
    for (const [, entries] of Object.entries(_.groupBy(separateEntries, getAvailabilityKey))) {
        const days = _.flatten(entries.map(x => x.days));
        groupedByDay.push({
            days,
            endTimeLocal: entries[0].endTimeLocal,
            startTimeLocal: entries[0].startTimeLocal,
            nextDay: entries[0].nextDay,
        });
    }

    return groupedByDay;
}

export const convertNextDayToSequentialPeriods = (availabilityDraft) => {
    const availabilityToPost = [];
    for (const entry of availabilityDraft) {
        if (entry.nextDay) {
            availabilityToPost.push({
                dayOfWeek: entry.day,
                startTimeLocal: entry.startTimeLocal,
                endTimeLocal: '2020-11-16T23:59:00',
            });

            availabilityToPost.push({
                dayOfWeek: DayOfWeekOrderNumber[(DayOfWeekOrderNumber[entry.day] + 1) % 7],
                startTimeLocal: '2020-11-16T00:00:00',
                endTimeLocal: entry.endTimeLocal,
            });
        } else {
            availabilityToPost.push({
                dayOfWeek: entry.day,
                startTimeLocal: entry.startTimeLocal,
                endTimeLocal: entry.endTimeLocal,
            });
        }
    }
    return availabilityToPost;
}

export const convertConsecutivePeriodsToNextDay = (availabilityDraft, nextDayMaxPeriodInMinutesThreshold) => {
    const result = [];
    const sortedEntries = _.sortBy(availabilityDraft, x => DayOfWeekOrderNumber[x.days[0]]);
    while (sortedEntries.length > 0) {
        const entry = sortedEntries.shift();
        const entryPeriodLengthMinutes = getPeriodLengthInMinutes([
            entry.startTimeLocal,
            entry.endTimeLocal
        ]);
        // Next Day could be only pairs,so we need to find following or predescendant entry

        const consecutiveEntryIndex = sortedEntries
            .findIndex(x => {
                if (DayOfWeekOrderNumber[x.days[0]] === ((DayOfWeekOrderNumber[entry.days[0]] + 1)  % 7)) {
                    if (extractTime(x.startTimeLocal) === '00:00' && extractTime(entry.endTimeLocal) === '23:59') {
                        return getPeriodLengthInMinutes([x.startTimeLocal, x.endTimeLocal]) < nextDayMaxPeriodInMinutesThreshold;
                    }

                    return false;
                }

                if (DayOfWeekOrderNumber[entry.days[0]] === ((DayOfWeekOrderNumber[x.days[0]] + 1)  % 7)) {
                    if (extractTime(entry.startTimeLocal) === '00:00' && extractTime(x.endTimeLocal) === '23:59') {
                        return entryPeriodLengthMinutes < nextDayMaxPeriodInMinutesThreshold;
                    }

                    return false;
                }

                return false;
            });

        if (consecutiveEntryIndex < 0) {
            result.push(entry);
            continue;
        }

        const consecutiveEntry = sortedEntries[consecutiveEntryIndex];

        sortedEntries.splice(consecutiveEntryIndex, 1);

        let isEntryBeforeConsecutive = false;

        if (DayOfWeekOrderNumber[entry.days[0]] === 6 && DayOfWeekOrderNumber[consecutiveEntry.days[0]] === 0) {
            isEntryBeforeConsecutive = true;
        } else if (DayOfWeekOrderNumber[entry.days[0]] === 0 && DayOfWeekOrderNumber[consecutiveEntry.days[0]] === 6) {
            isEntryBeforeConsecutive = false;
        } else isEntryBeforeConsecutive = DayOfWeekOrderNumber[entry.days[0]] < DayOfWeekOrderNumber[consecutiveEntry.days[0]];

        if (isEntryBeforeConsecutive) {
            result.push({
                ...entry,
                endTimeLocal: consecutiveEntry.endTimeLocal,
                nextDay: true,
            });
        } else {
            result.push({
                ...consecutiveEntry,
                endTimeLocal: entry.endTimeLocal,
                nextDay: true,
            });
        }
    }

    return result;
}

export const getAvailabilityToPost = (availabilityDraft) => {
    const availabilityByDays = convertMultipleDaysEntryToSeparateEntries(availabilityDraft);
    const availabilityToPost = convertNextDayToSequentialPeriods(availabilityByDays);

    const alwaysAvailable = availabilityDraft.length === 1 && availabilityDraft[0].alwaysAvailable;
    if (availabilityDraft.length === 0 || availabilityToPost.length === 0 && !alwaysAvailable) {
        return {
            isValid: false,
            errorMessage: `Cannot save product availability, no any available periods selected, please select any or pick Always Available option`,
        };
    }

    for (const entry of availabilityToPost) {
        if (moment(entry.startTimeLocal) > moment(entry.endTimeLocal)) {
            return {
                isValid: false,
                errorMessage: `Cannot save product availability, ${entry.dayOfWeek} entry start time is after an end time`,
            };
        }
    }

    const intersections = getPeriodsIntersections(availabilityToPost);
    if (intersections.length > 0) {
        const daysWithIntersections = [...new Set(intersections.map(x => x[0].dayOfWeek))].join(', ');
        return {
            isValid: false,
            errorMessage: `Cannot save product availability, following day(s) have time intersections: ${daysWithIntersections}`,
        };
    }

    return {
        isValid: true,
        availabilityToPost,
    };
}

export const minutesOfDay = m => {
    let momentEntry = typeof m === 'string' ? moment(m, 'YYYY-M-DTHH:mm:ss') : m;
    return momentEntry.minutes() + momentEntry.hours() * 60;
};

export const periodsHasIntersection = (period1, period2) => {
    console.assert((period1[0] <= period1[1]) && (period2[0] <= period2[1]), 'Periods should be in ascending order');

    const notIntersecting = (period1[0] <= period2[0] && period1[1] <= period2[0])
        || (period1[0] >= period2[1] && period1[1] >= period2[1]);
    return !notIntersecting;
};

export const getPeriodsIntersections = (periods) => {
    const groupedByDay = _.groupBy(periods, x => x.dayOfWeek);
    const intersections = [];
    for (const [, entries] of Object.entries(groupedByDay)) {
        const combinations = _.combinations(entries, 2);
        for (const [entry1, entry2] of combinations) {
            let period1 = [minutesOfDay(entry1.startTimeLocal), minutesOfDay(entry1.endTimeLocal)];
            let period2 = [minutesOfDay(entry2.startTimeLocal), minutesOfDay(entry2.endTimeLocal)];
            period1 = [Math.min(period1[0], period1[1]), Math.max(period1[0], period1[1])];
            period2 = [Math.min(period2[0], period2[1]), Math.max(period2[0], period2[1])];
            if (periodsHasIntersection(period1, period2)) {
                intersections.push([entry1,  entry2])
            }
        }
    }

    return intersections;
};

export const getPeriodLengthInMinutes = (period) => {
    return Math.abs(minutesOfDay(period[0]) - minutesOfDay(period[1]));
}

export const extractTime = x => moment(x, 'YYYY-M-DTHH:mm:ss').format('HH:mm');
