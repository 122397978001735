<template>
  <v-row class="bulk-edit-select-row" no-gutters>
      <v-col align-self="center" md="4">
          <div class="pl-5 pr-5">
              <v-btn
                  v-model="enabled"
                  @click="onToggleChange(!enabled)"
                  small
                  icon
                  active-class="active"
                  :color="enabled ? 'rgb(98, 197, 241)' : null"
              >
                  <v-icon>mdi-power-standby</v-icon>
              </v-btn>
              <span class="font-weight-bold ml-3">{{label}}</span>
          </div>
      </v-col>
        <v-col>
          <v-select
            :class="`${this.changeKey}-select-target`"
            :disabled="!enabled"
            :items="items"
            v-model="value"
            :label="label"
            color="#93BD20"
            @input="onSelectInput"
            attach
          />
        </v-col>
  </v-row>
</template>

<script>
export default {
    name: "BulkEditRow",
    data() {
        return {
            enabled: false,
            value: undefined,
        };
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        changeKey: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        }
    },
    methods: {
        onToggleChange(val) {
            this.enabled = val;
            if (!val) {
                this.value = undefined;
                this.$emit("input", { key: this.changeKey, value: undefined });
            }
        },
        onSelectInput(val) {
            this.$emit("input", { key: this.changeKey, value: this.value });
        },
    },
};
</script>

<style lang="scss" scoped>
    .bulk-edit-select-row {
        min-height: 68px;
        border: 1px solid #e4e4e4;
        margin-top: -1px;
    }
</style>
