var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.primaryTitleBarActions, title: "BulkEdit" },
      }),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "splitpanes",
                [
                  _c(
                    "pane",
                    {
                      staticClass: "pr-1",
                      attrs: { "min-size": "20", size: "40" },
                    },
                    [
                      _c("SelectionCard", {
                        model: {
                          value: _vm.selectedProducts,
                          callback: function ($$v) {
                            _vm.selectedProducts = $$v
                          },
                          expression: "selectedProducts",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "pane",
                    {
                      staticClass: "pl-1",
                      attrs: { "min-size": "20", size: "60" },
                    },
                    [_c("BulkEditForm", { on: { input: _vm.onFormInput } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }