<template>
  <v-row class="ml-1"  no-gutters>
    <v-col md="3">
      <v-checkbox hide-details color="#93BD20" :label="label" @change="onToggleChange"/>
    </v-col>
    <v-col md="5">
      <slot/>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: "BulkEditRow",
    data() {
        return {
            test: "hello world",
        };
    },
    props: {
        label: {
            type: String,
            required: true
        },
        changeKey: {
            type: String,
            required: true
        }
    },
    methods: {
        onToggleChange(val) {
            this.$emit('toggle', {
                key: this.changeKey,
                value: val
            })
        }
    }
};
</script>
