var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "bulk-edit-time-row pb-3", attrs: { "no-gutters": "" } },
    [
      _c("v-col", { attrs: { md: "4" } }, [
        _c(
          "div",
          { staticClass: "pl-5 pr-5", staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  small: "",
                  icon: "",
                  "active-class": "active",
                  color: _vm.enabled ? "rgb(98, 197, 241)" : null,
                },
                on: {
                  click: function ($event) {
                    _vm.enabled = !_vm.enabled
                  },
                },
                model: {
                  value: _vm.enabled,
                  callback: function ($$v) {
                    _vm.enabled = $$v
                  },
                  expression: "enabled",
                },
              },
              [_c("v-icon", [_vm._v("mdi-power-standby")])],
              1
            ),
            _c("span", { staticClass: "font-weight-bold ml-3" }, [
              _vm._v("Time"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "v-col",
        { staticClass: "pr-5 pa-1" },
        [
          _c("time-selection", {
            attrs: { disabled: !_vm.enabled, value: _vm.availability },
            on: {
              input: function ($event) {
                _vm.availability = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }