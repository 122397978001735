<template>
  <div>
    <ContextTitle :passedActions="primaryTitleBarActions" title="BulkEdit" />
    <v-container class="pa-0" fluid>
      <v-row no-gutters >
          <splitpanes>
              <pane class="pr-1" min-size="20" size="40">
                  <SelectionCard v-model="selectedProducts"/>
              </pane>
              <pane class="pl-1" min-size="20" size="60">
                  <BulkEditForm @input="onFormInput"/>
              </pane>
          </splitpanes>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SelectionCard from "components/bulk_edit_product_selection.vue";
import BulkEditForm from "components/bulk_edit_form.vue"
import ContextTitle from "components/context_title.vue";
import { mapActions } from 'vuex';
import clone from 'clone'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

export default {
    name: "StoreProductBulkEdit",
    data() {
        return {
            selectedProducts: [],
            dirty: true,
            valid: true,
            changedProperties: {}
        };
    },
    components: {
        SelectionCard,
        BulkEditForm,
        ContextTitle,
        Splitpanes,
        Pane,
    },
    computed: {
        primaryTitleBarActions() {
            const close = {
                type: "faded",
                display: "Close",
                run: () => {
                    this.leave();
                },
            };

            if (!this.dirty || !this.valid) return [close];
            const saveClose = {
                type: "proceed-green",
                display: "Save",
                run: () => {
                    this.submit()
                },
            };
            return [close, saveClose];
        },
    },
    methods: {
        onFormInput(val) {
            this.changedProperties = val
        },
        submit() {
            let storeWaypointID = this.$route.params.store;

            let inventoryItemIDs = this.selectedProducts.filter(el => !el.group).map(el => el.id)

            if (inventoryItemIDs.length === 0) {
                this.toast('Please select at least 1 product to edit.', "error");
                return;
            }

            let changedProperties = clone(this.changedProperties)

            const availability = this.changedProperties.availability;
            if (availability) {
                const {isValid, errorMessage, availabilityToPost} = availability;
                if (!isValid) {
                    this.toast(errorMessage, "error");
                    return;
                } else {
                    changedProperties.availability = availabilityToPost;
                }
            }

            let payload = {
                storeWaypointID,
                payload: {
                    inventoryItemIDs,
                    changedProperties,
                }
            }

            this.submitBulkEdit(payload).then((success) => {
                if (success)
                    this.$router.push({
                        name: 'store-products'
                    })
            });
        },
        ...mapActions(['submitBulkEdit'])
    }
};
</script>

<style lang="scss">
    .splitpanes--vertical {
        .splitpanes__splitter {
            min-width: 7px;
        }
    }
</style>
