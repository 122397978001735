var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "bulk-edit-platforms-row", attrs: { "no-gutters": "" } },
    [
      _c("v-col", { attrs: { md: "4" } }, [
        _c(
          "div",
          { staticClass: "pl-5 pr-5", staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  small: "",
                  icon: "",
                  "active-class": "active",
                  color: _vm.enabled ? "rgb(98, 197, 241)" : null,
                },
                on: {
                  click: function ($event) {
                    return _vm.onToggleChange(!_vm.enabled)
                  },
                },
                model: {
                  value: _vm.enabled,
                  callback: function ($$v) {
                    _vm.enabled = $$v
                  },
                  expression: "enabled",
                },
              },
              [_c("v-icon", [_vm._v("mdi-power-standby")])],
              1
            ),
            _c("span", { staticClass: "font-weight-bold ml-3" }, [
              _vm._v(_vm._s(_vm.label)),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "v-col",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.value, function (platform) {
              return _c(
                "v-col",
                {
                  key: platform.order,
                  staticClass: "pt-0",
                  attrs: { sm: "6" },
                },
                [
                  _c("v-switch", {
                    attrs: {
                      label: platform.display,
                      mandatory: "",
                      disabled: !_vm.enabled,
                    },
                    on: { change: _vm.onPlatformChange },
                    model: {
                      value: _vm.value[platform.id]["enabled"],
                      callback: function ($$v) {
                        _vm.$set(_vm.value[platform.id], "enabled", $$v)
                      },
                      expression: "value[platform.id]['enabled']",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }