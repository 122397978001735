<template>
  <v-row class="bulk-edit-platforms-row" no-gutters>
      <v-col md="4">
          <div class="pl-5 pr-5" style="margin-top: 15px">
              <v-btn
                  v-model="enabled"
                  @click="onToggleChange(!enabled)"
                  small
                  icon
                  active-class="active"
                  :color="enabled ? 'rgb(98, 197, 241)' : null"
              >
                  <v-icon>mdi-power-standby</v-icon>
              </v-btn>
              <span class="font-weight-bold ml-3">{{label}}</span>
          </div>
      </v-col>
    <v-col>
      <v-row no-gutters>
        <v-col
          sm="6"
          class="pt-0"
          v-for="platform in value"
          :key="platform.order"
        >
            <v-switch
                v-model="value[platform.id]['enabled']"
                :label="platform.display"
                @change="onPlatformChange"
                mandatory
                :disabled="!enabled"
            ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { platformMap } from "helpers/products";
import _ from "lodash";
import clone from "clone";

export default {
    name: "BulkEditPlatformsRow",
    data() {
        return {
            enabled: false,
            value: {},
            toggle_exclusive: 0,
        };
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        changeKey: {
            type: String,
            required: true,
        },
        radioLabels: {
            type: Array,
            require: true,
        },
    },
    computed: {
        platforms() {
            let keys = Object.keys(platformMap);
            return keys.map((el) => {
                return platformMap[el];
            });
            return _.sortBy(filteredPlatforms, (el) => el.order);
        },
    },
    methods: {
        onToggleChange(val) {
            this.enabled = val;
            if (!val) {
                this.value = undefined;
                this.$emit("input", { key: this.changeKey, value: undefined });
                this.init();
            } else {
                this.$emit("input", { key: this.changeKey, value: this.value });
            }
        },
        onPlatformChange(val) {
            this.$emit("input", { key: this.changeKey, value: this.value });
        },
        init() {
            this.value = clone(platformMap);
            Object.keys(this.value).forEach((element) => {
                this.$set(this.value[element], "enabled", 1);
            });
        },
    },
    created() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
    .bulk-edit-platforms-row {
        min-height: 68px;
        border: 1px solid #e4e4e4;
        margin-top: -1px;
    }
</style>
