<template>
  <v-row class="bulk-edit-time-row pb-3" no-gutters>
    <v-col md="4">
        <div class="pl-5 pr-5" style="margin-top: 15px">
            <v-btn
                v-model="enabled"
                @click="enabled = !enabled"
                small
                icon
                active-class="active"
                :color="enabled ? 'rgb(98, 197, 241)' : null"
            >
                <v-icon>mdi-power-standby</v-icon>
            </v-btn>
            <span class="font-weight-bold ml-3">Time</span>
        </div>
    </v-col>
    <v-col class="pr-5 pa-1">
        <time-selection
            :disabled="!enabled"
            :value="availability"
            @input="availability = $event"
        />
    </v-col>
  </v-row>
</template>

<script>
import TimeSelection from "components/product_edit/time_selection";
import {
    convertMultipleDaysEntryToSeparateEntries,
    convertNextDayToSequentialPeriods, getAvailabilityToPost
} from "helpers/availability";

export default {
    name: "BulkEditTimeRow",
    components: {
        TimeSelection,
    },
    data() {
        return {
            allDay: undefined,
            enabled: false,
            startTimeMenu: false,
            startTime: "",
            endTime: "",
            endTimeMenu: false,
            availability: [{
                alwaysAvailable: true,
                days: [],
                startTimeLocal: '2020-11-16T00:00:00',
                endTimeLocal: '2020-11-16T23:59:00',
                nextDay: false,
            }],
        };
    },
    methods: {
        emitInputEvent() {
            if (!this.enabled) {
                this.$emit("input", undefined);
            }

            const {isValid, errorMessage, availabilityToPost} = getAvailabilityToPost(this.availability);

            this.$emit("input", {
                availability: {
                    isValid,
                    errorMessage,
                    availabilityToPost,
                },
            });
        },
    },
    watch: {
        enabled() {
            this.emitInputEvent();
        },
        allDay() {
            this.emitInputEvent();
        },
        availability:{
            deep: true,
            handler() {
                this.emitInputEvent();
            }
        },
    }
};
</script>

<style lang="scss" scoped>
    .bulk-edit-time-row {
        min-height: 68px;
        border: 1px solid #e4e4e4;
        margin-top: -1px;
        &__toggle {
            height: 28px;
        }
    }
</style>
