<template>
  <v-card class="upsell-selection-card">
    <v-card-title><v-icon color="black">mdi-numeric-1-circle-outline</v-icon> <span class="ml-2">Select Products</span></v-card-title>
    <div v-bar class="scroll-container overflow-y-auto">
      <v-card-text class="pa-0">
        <v-data-table
          ref="sortableList"
          class="el2"
          v-model="selectedItems"
          :items="items"
          :headers="headers"
          hide-default-footer
          hide-default-header
          dense
          disable-pagination
        >
          <template v-slot:item="{isSelected, item}">
            <tr
              class="upsell-selection-row"
              :active="isSelected"
              @click.stop="toggleEither(item)"
            >
              <td v-if="item.group">
                <v-row class="pt-1">
                  <div class="group-icon"></div>
                  <div class="group-title">{{ item.title }}</div>
                </v-row>
              </td>
              <td v-else>
                <div class="item-title">{{ item.title }}</div>
              </td>

              <td>
                <v-checkbox
                  color="rgba(98, 197, 241, 1)"
                  :input-value="isSelected"
                  @click.stop="item.group ? toggleCategory(item) : toggleItem(item)"
                  hide-details
                ></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContextTitle from "components/context_title.vue";

export default {
    data() {
        return {
            selectedItems: [],
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name"
                },
                {
                    sortable: false,
                    text: "",
                    value: "state"
                }
            ],
            titleBarActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.$emit("close");
                    }
                },
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        this.$emit("save", this.selectedItems);
                    }
                }
            ]
        };
    },
    props: {
        value: {
            type: Array,
            required: true,
        }
    },
    components: {
        ContextTitle,
    },
    computed: {
        items() {
            if (
                this.productCategories == undefined ||
                this.productCategories.length == 0 ||
                this.selectedItems == undefined
            )
                return [];

            var items = [];

            let selectedItemSet = new Set(this.selectedItems.map(el => el.id));

            for (let category of this.productCategories) {
                if (category.inventoryTitleDescription == "SHOW ALL" || !category.children.length) continue;

                var allSelected = true;
                var categoryItem = {
                    id: category.inventoryTitleID,
                    group: true,
                    title: category.inventoryTitleDescription,
                    parentID: -1
                };
                items.push(categoryItem);

                for (let item of category.children) {
                    if (!selectedItemSet.has(item.id)) allSelected = false;
                    items.push({
                        id: item.id,
                        group: false,
                        title: item.inventoryItemName,
                        parentID: category.inventoryTitleID
                    });
                }
                if (
                    allSelected &&
          !this.selectedItems.findIndex(el => el.id == categoryItem.id)
                )
                    this.selectedItems.push(categoryItem);
            }

            return items;
        },
        ...mapGetters(["productCategories"]),
    },
    methods: {
        toggleEither(item) {
            if (item.group) {
                this.toggleCategory(item)
            } else {
                this.toggleItem(item)
            }
        },
        toggleItem(item) {
            let itemIndex = this.selectedItems.findIndex(el => el.id == item.id);

            let selected = itemIndex != -1;
            if (!selected) {
                this.selectedItems.push(item);
                let selectedItemSet = new Set(this.selectedItems.map(el => el.id));
                let siblings = this.items.filter(el => el.parentID == item.parentID);
                let allSelected = siblings.reduce((result, el) => {
                    return result && selectedItemSet.has(el.id);
                }, true);

                if (allSelected) {
                    let parent = this.items.find(el => el.id == item.parentID);
                    this.selectedItems.push(parent);
                }
            } else {
                this.selectedItems.splice(itemIndex, 1);
                let categoryIndex = this.selectedItems.findIndex(
                    el => el.id == item.parentID
                );
                if (categoryIndex != -1) {
                    this.selectedItems.splice(categoryIndex, 1);
                }
            }
        },
        toggleCategory(categoryItem) {
            let categoryIndex = this.selectedItems.findIndex(
                el => el.id == categoryItem.id
            );
            let selected = categoryIndex != -1;

            if (!selected) {
                this.selectedItems.push(categoryItem);
            } else {
                this.selectedItems.splice(categoryIndex, 1);
            }

            let category = this.productCategories.find(
                el => el.inventoryTitleID == categoryItem.id
            );
            if (category === undefined) return;

            for (let item of category.children) {
                let index = this.selectedItems.findIndex(el => el.id == item.id);
                if (selected && index != -1) {
                    this.selectedItems.splice(index, 1);
                } else if (!selected && index == -1) {
                    this.selectedItems.push({
                        id: item.id,
                        group: false,
                        title: item.inventoryItemName
                    });
                }
            }
        }
    },
    created() {

    },
    watch: {
        selectedItems() {
            this.$emit('input', this.selectedItems)
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
@import "~css/vuebar.css";

.v-input--selection-controls.v-input {
  margin-top: 0;
}
.upsell-selection-card {
  .v-data-table {
    tbody {
      tr:hover:not(.v-data-table__expand-row) {
        background: rgba(98, 197, 241, 0.2);
      }
      tr[active] {
        background: rgba(98, 197, 241, 0.4);
      }
      tr {
        cursor: pointer;
        td {
          color: #585858 !important;
          vertical-align: middle;
          height: 41px;
          font-size: 14px;
          padding-left: 30px;
          width: 500px;
        }
      }
    }
  }
  .scroll-container {
    height: 74vh;
    margin-bottom: 5px;
  }
  .upsell-selection-row {
    color: #585858 !important;
    font-size: 14px;
    vertical-align: middle;
  }
  .group-title {
    padding-left: 20px;
  }
  .item-title {
    padding-left: 50px;
  }
  .group-icon {
    @include background-image("", "folder_open_logo.svg", "../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
  }
  .v-icon .v-icon:after {
    opacity: 1 !important;
  }
}
</style>
