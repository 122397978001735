var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "ml-1", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { md: "3" } },
        [
          _c("v-checkbox", {
            attrs: { "hide-details": "", color: "#93BD20", label: _vm.label },
            on: { change: _vm.onToggleChange },
          }),
        ],
        1
      ),
      _c("v-col", { attrs: { md: "5" } }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }